/* eslint-disable import/prefer-default-export */
import { useStaticQuery, graphql } from 'gatsby';

export const useNewBlogNavItems = () => {
  const { allCosmicjsNavItems } = useStaticQuery(
    graphql`
      query NavQuery {
        allCosmicjsNavItems {
          edges {
            node {
              slug
              title
              metadata {
                dropdown_items {
                  header
                  items {
                    title
                    link
                  }
                }
                link
                nav_cta {
                  header
                  description
                  button_text
                  action
                  url
                }
              }
            }
          }
        }
      }
    `,
  );

  return allCosmicjsNavItems.edges.map(({ node }) => node);
};
