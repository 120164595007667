/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '@cleverrealestate/clever-components-v2/dist/components/Footer';
import NavBar from '@cleverrealestate/clever-components-v2/dist/components/NavBar';
import BrowserNotSupported from '@cleverrealestate/clever-components-v2/dist/components/BrowserNotSupported';
import './Layout.css';
import { useNewBlogNavItems } from '../utils/newNavItems-utils';

const Layout = ({
  children,
  showFooter,
  extraClasses,
  hideLinks,
}) => {
  const { footer } = useStaticQuery(
    graphql`
      {
        footer: cosmicjsFooter {
          metadata {
            sections {
              section {
                metadata {
                  links {
                    blank
                    label
                    url
                  }
                }
                title
              }
            }
            legal
          }
        }
      }
    `,
  );

  const menuItems = useNewBlogNavItems();
  const [showBrowserSupport, setShowBrowserSupport] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
      const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
      setShowBrowserSupport(isIE);
    }
  }, []);

  return (
    <>
      <NavBar menuItems={menuItems} />
      {showBrowserSupport && <BrowserNotSupported onClose={() => setShowBrowserSupport(false)} />}
      <div
        className={classNames(['top-container', extraClasses])}
      >
        <main>{children}</main>
      </div>
      { showFooter ? <Footer content={footer.metadata} hideLinks={hideLinks} /> : null}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
  extraClasses: PropTypes.any,
  hideLinks: PropTypes.bool,
};

Layout.defaultProps = {
  showFooter: true,
  extraClasses: {},
  hideLinks: false,
};

export default Layout;
